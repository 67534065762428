import { Injectable } from "@angular/core";
import { CostCenter } from "@data/schemas/cost-center";
import { DataList } from "@data/schemas/filter/data-list";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CostCenterService {
	constructor(private baseCrudBff: BaseCrudBffService) {}

	findAll(searchText = ""): Observable<DataList<CostCenter>> {
		return this.baseCrudBff.getAll<DataList<CostCenter>>({
			path: "cost-center",
			params: { q: searchText }
		});
	}
}
