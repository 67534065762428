import { Injectable } from "@angular/core";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { Observable } from "rxjs";
import { DataList } from "@data/schemas/filter/data-list";
import { Project } from "@data/schemas/project";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { convertHttpParamsToQueryString } from "@shared/utils/filter-params-utils";

@Injectable({
	providedIn: "root"
})
export class ProjectService {
	readonly PROJECT_ENDPOINT = "projects";

	constructor(private dataBff: BaseCrudBffService) {}

	getAllProject(
		httpParamsConfig?: HttpParamsConfig
	): Observable<DataList<Project>> {
		const params = convertHttpParamsToQueryString({
			...httpParamsConfig
		});
		return this.dataBff.getAll<DataList<Project>>({
			path: this.PROJECT_ENDPOINT,
			params
		});
	}

	findAll(searchText = ""): Observable<Project[]> {
		return this.dataBff.getAll({
			path: this.PROJECT_ENDPOINT,
			params: { q: searchText }
		});
	}

	getProjectById(id: number): Observable<Project> {
		return this.dataBff.getById<Project>(this.PROJECT_ENDPOINT, id);
	}

	saveProject(project: Partial<Project>): Observable<Partial<Project>> {
		return this.dataBff.save({
			path: this.PROJECT_ENDPOINT,
			object: project
		});
	}

	deleteProject(id: number): Observable<unknown> {
		return this.dataBff.delete({
			path: this.PROJECT_ENDPOINT,
			id
		});
	}
}
