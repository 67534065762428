import { Injectable } from "@angular/core";
import { Company } from "@data/schemas/company/company";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { BehaviorSubject, map, Observable, Subject } from "rxjs";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { DataList } from "@data/schemas/filter/data-list";
import { convertHttpParamsToQueryString } from "@shared/utils/filter-params-utils";
import { environment } from "@env";
import { AuthService } from "@core/services/auth/auth.service";
import { ignoreHttpError } from "@core/interceptors/http-error.interceptor";

@Injectable({
	providedIn: "root"
})
export class CompanyService {
	private readonly COMPANY_ENDPOINT = "companies";
	private readonly SEARCH_TEXT_ENDPOINT = "search-text";

	private notifyReload = new BehaviorSubject<boolean>(true);
	notifyReload$ = this.notifyReload.asObservable();

	constructor(
		private baseCrudBff: BaseCrudBffService,
		private authService: AuthService
	) {}

	private refreshCompanyList = new Subject<Company>();
	refreshCompanyList$ = this.refreshCompanyList.asObservable();

	getSearchCompaniesWithSearchTextUrl() {
		return `${environment.API_BFF}/${this.COMPANY_ENDPOINT}/${this.SEARCH_TEXT_ENDPOINT}?hashedFingerprint=${this.authService.getHashedFingerprint()}&authorization=${this.authService.getToken()}`;
	}

	getAllCompanies(
		httpParamsConfig?: HttpParamsConfig
	): Observable<Company[]> {
		const params = convertHttpParamsToQueryString({
			...httpParamsConfig
		});
		return this.baseCrudBff.getAll<Company[]>({
			path: this.COMPANY_ENDPOINT,
			params
		});
	}

	getById(id: number): Observable<Company> {
		return this.baseCrudBff.getById<Company>(this.COMPANY_ENDPOINT, id);
	}

	searchCompanies(
		searchText: string,
		limit = "3",
		hideBlocked = false
	): Observable<Company[]> {
		return this.baseCrudBff.getAll({
			path: this.COMPANY_ENDPOINT,
			params: {
				q: searchText,
				limit,
				ativo: hideBlocked
			}
		});
	}

	createCompany(company: Partial<Company>) {
		return this.baseCrudBff.save({
			path: this.COMPANY_ENDPOINT,
			object: company
		});
	}

	updateCompany(company: Partial<Company>) {
		return this.baseCrudBff.save({
			path: this.COMPANY_ENDPOINT,
			object: company,
			patch: true
		});
	}

	createCompanyAndSalesProposal(
		company: Partial<Company>
	): Observable<{ salesProposalId: number; companyId: number }> {
		return this.baseCrudBff
			.save({
				path: `${this.COMPANY_ENDPOINT}/sales-proposals`,
				object: company
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							salesProposalId: number;
							companyId: number;
						}
				)
			);
	}

	updateCompanyAndCreateSalesProposal(
		company: Partial<Company>
	): Observable<{ salesProposalId: number }> {
		return this.baseCrudBff
			.save({
				path: `${this.COMPANY_ENDPOINT}/sales-proposals`,
				object: company,
				patch: true
			})
			.pipe(
				map(
					(result) => result as unknown as { salesProposalId: number }
				)
			);
	}

	createCompanyAndSalesOrder(
		company: Partial<Company>
	): Observable<{ salesOrderId: number }> {
		return this.baseCrudBff
			.save({
				path: `${this.COMPANY_ENDPOINT}/sales-order`,
				object: company
			})
			.pipe(
				map((result) => result as unknown as { salesOrderId: number })
			);
	}

	updateCompanyAndCreateSalesOrder(
		company: Partial<Company>
	): Observable<{ salesOrderId: number }> {
		return this.baseCrudBff
			.save({
				path: `${this.COMPANY_ENDPOINT}/sales-order`,
				object: company,
				patch: true
			})
			.pipe(
				map((result) => result as unknown as { salesOrderId: number })
			);
	}

	getAllCompanyByPriceTable(
		idPriceTable: number,
		httpParams?: HttpParamsConfig
	): Observable<DataList<Company>> {
		const params = this.getAllFiltersParams(idPriceTable, httpParams);
		return this.baseCrudBff.getAll({
			path: `${this.COMPANY_ENDPOINT}/list-companies-with-price-table`,
			params
		});
	}

	getAllCompanyRepresentatives(searchText = ""): Observable<Company[]> {
		return this.baseCrudBff
			.getAll<DataList<Company>>({
				path: `${this.COMPANY_ENDPOINT}/list-sales-representative`,
				params: {
					searchText,
					size: 30
				}
			})
			.pipe(map((result) => result.content));
	}

	private getAllFiltersParams(
		idPriceTable: number,
		httpParamsConfig?: HttpParamsConfig
	): {
		[param: string]: string | number;
	} {
		let params = {};
		if (httpParamsConfig) {
			const { fields, ...filters } = httpParamsConfig;
			if (fields) {
				fields.push({
					key: "idPriceTable",
					value: idPriceTable.toString()
				});
				fields.forEach((item) => {
					const fieldsParams = {
						[item.key]: item.value
					};
					params = { ...params, ...fieldsParams };
				});
			} else {
				const fieldIdPriceTable = {
					idPriceTable: idPriceTable.toString()
				};
				params = { ...params, ...fieldIdPriceTable };
			}
			params = { ...params, ...filters };
		}
		return params;
	}

	setRefreshCompanyList(items: Company): void {
		this.refreshCompanyList.next(items);
	}

	actionNotifyReload(): void {
		this.notifyReload.next(true);
	}

	researchCnpj(searchText = ""): Observable<unknown> {
		return this.baseCrudBff.getAll({
			path: `${this.COMPANY_ENDPOINT}/research-cnpj`,
			params: {
				searchText
			},
			context: ignoreHttpError()
		});
	}
}
